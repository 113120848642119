import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import {isNotEmpty} from '../utils/Validator';
import { parseDate } from '../utils/Utils';
import "../table.css";
import Button from "@material-ui/core/Button";
import { formatDate } from '../utils/Format';

const headCells = [
    { id: 'userFirstName',fid:'userFirstName', numeric: false, disablePadding: true, label: 'First Name', filterLable: ' Name', width: '8rem',sortable: true },
    { id: 'userLastName', fid:'userLastName', numeric: false, disablePadding: true, label: 'Last Name', filterLable: ' Name', width: '8rem',sortable: true },
    { id: 'userNm', fid:'userNm', numeric: false, disablePadding: true, label: 'Username', filterLable: ' Username', width: '9rem',sortable: true },
    { id: 'emailAddr', fid:'emailAddr', numeric: false, disablePadding: true, label: 'Email Address', filterLable: ' Email', width: '12em',sortable: true },
    { id: 'statusDesc', fid:'statusDesc', numeric: false, disablePadding: true, label: 'Account Status', filterLable: ' Status', width: '5rem',sortable: true },
    { id: 'typeDesc',fid:'typeDesc', numeric: false, disablePadding: true, label: 'Account Type', filterLable: ' Type', width: '8rem',sortable: true },
    { id: 'grpInfoId',fid:'grpInfoId', numeric: false, disablePadding: true, label: 'Group ID', filterLable: ' Group ID', width: '5rem',sortable: true },
    { id: 'createdDt',fid:'createdDt', numeric: false, disablePadding: true, label: 'Create Date', filterLable: ' Create Date', width: '2rem',sortable: true },
    { id: 'lastLoginDt',fid:'lastLoginDt', numeric: false, disablePadding: true, label: 'Last Login Date', filterLable: ' Last Login Date', width: '2rem',sortable: true }

];

function desc(a, b, orderBy) {
    let c = a[orderBy];
    let d = b[orderBy];

    if (isNotEmpty(c)) {
        if (typeof c === "string") {
            c = c.toUpperCase();
        } else if (c instanceof Date) {
            c = parseDate(c);
        }
    } else {
        c = "";
    }

    if (isNotEmpty(d)) {
        if (typeof d === "string") {
            d = d.toUpperCase();
        } else if (d instanceof Date) {
            d = parseDate(d);
        }
    } else {
        d = "";
    }

    if (d < c) {
        return -1;
    }

    if (d > c) {
        return 1;
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



function EnhancedTableHead(props) {
    const { updateAcct, classes, order, orderBy, onRequestSort, onRequestFilter } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const onFilter = property => event => {
        onRequestFilter(event.target.value, property);

    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}                        
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ width: headCell.width, paddingBottom:0  }}
                        autoFocus={headCell.id === 'userFirstName'}
                    >
                        <div>
                            {headCell.sortable &&
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={order}
                                    onClick={createSortHandler(headCell.id)}
                                >                                    
                                    <div>
                                        {headCell.label}

                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>) : null}
                                    </div>
                                </TableSortLabel>
                            }
                             {!headCell.sortable &&
                                <div>
                                    {headCell.label}
                                </div>
                            }                           
                        </div>
                    </TableCell>
                ))}
                {updateAcct ? '' :
                <TableCell style={{ width: '5rem', textAlign: 'left' }}>
                    Update Account
                </TableCell>
                }
               
            </TableRow>
            <TableRow className='filter-row'>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id+1}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        style={{ maxWidth: headCell.width, paddingBottom:0}}
                        autoFocus={headCell.id === 'userFirstName'}
                    >
                        <div  style={{paddingRight:'1rem',verticalAlign:'bottom'}}>                            
                            <TextField
                                id="filterId"
                                fullWidth
                                autoFocus={headCell.id === 'userFirstName'}
                                style={{height:'.7'}}
                                placeholder={`Enter ${headCell.filterLable}`}
                                margin="dense"
                                variant="outlined"
                                onChange={onFilter(headCell.fid)}
                            />                            
                        </div>

                    </TableCell>

                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onRequestFilter: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {

    return ({}
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),     
    },   
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}               
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
      );
}

const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 450,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

});

class ManageUsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc', orderBy: 'userFirstName', page: 0, rowsPerPage: 10
        }

    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property });
    }

    handleRequestFilter = (value, property) => {
        if( value.length < 1){
            this.props.setFilter(this.props.unfilteredUsers)
            return true;
        }else{

            this.props.setFilter(this.props.unfilteredUsers.filter(user => {
                if( user[property] == null) {
                    return false;
                }
                if(("updateDt" === property) || ("addedDt" === property)){
                                    value = formatDate(value);
                                }
                let comp = isNaN(user[property]) ? user[property].toUpperCase():  user[property] + " ";
                if(  comp.search(value.toUpperCase()) !== -1) {
                    this.setState({ page: 0 });
                    return value.toUpperCase();
                }
                
                return false;
             }
            ))

        }
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = this.props.users.map(n => n.userNm);
            this.props.setSelected(newSelecteds);
            return;
        }
        this.props.setSelected([]);
    }
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage })
    }

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value })
    }

    isMirror = (user) => {

        return this.props.isUserSearch === true && (user.typeCd === 'PRUS' || user.typeCd === 'PRAD') && user.statusCd === 'ACTIV';
    }

    showDropDown = (user) => {
        return this.props.userType !== 'IRAD' && (isNotEmpty(user.userActions) || this.isMirror(user));
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                        component="div"
                        count={this.props.users.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}                        
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    {/*  <EnhancedTableToolbar numSelected={selected.length} />*/}
                    <div className={classes.tableWrapper}>
                        <Table
                            className={`${classes.table} App-font`}
                            aria-labelledby="tableTitle"
                            size='small'
                            striped="true"
                            aria-label="Manage Tins table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                updateAcct={this.props.handleSelectAction === null}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleRequestSort}
                                onRequestFilter={this.handleRequestFilter}
                                rowCount={this.props.users.length}

                            />
                            <TableBody>
                                {stableSort(this.props.users, getSorting(this.state.order, this.state.orderBy))
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((row, index) => {

                                        const labelId = `enhanced-table-Select-${index}`;

                                        return (
                                            <TableRow
                                                tabIndex={-1}
                                                key={index}
                                                style={{
                                                    padding: '5px 20px',
                                                    height: 25,
                                                    backgroundColor: index % 2 ? '#fafafa' : 'white'
                                                }}

                                            >

                                                <TableCell component="th" id={labelId} scope="row"
                                                    size='small'>
                                                    {row.userFirstName}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                    size='small'>
                                                    {row.userLastName}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                    size='small'>
                                                    {row.userNm}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                    size='small'>
                                                    {row.emailAddr}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                    size='small'>
                                                    {row.statusDesc}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row"
                                                    size='small'>
                                                    {row.typeDesc}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {row.grpInfoId}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                    {formatDate(row.createdDt)}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row"
                                                           size='small'>
                                                              {formatDate(row.lastLoginDt)}
                                                   
                                                </TableCell>
                                                {this.props.handleSelectAction === null ? '' :
                                                <TableCell  size='small' scope="row" component="th">

                                                    {this.showDropDown(row)  ?
                                                    <TextField
                                                        variant='outlined'
                                                        onChange={(event) => {
                                                            this.props.handleSelectAction(row.id, event.target.value);
                                                        }}
                                                        select
                                                        margin="dense"
                                                        style={{ minWidth: '7em' , color:'#FFF'}}
                                                        label="Select"
                                                    >
                                                        {row.userActions.map((option)=> (
                                                            isNotEmpty(option)?
                                                            <MenuItem value={option}>
                                                                {option}

                                                            </MenuItem> : <span/>

                                                        ))}
                                                        {this.isMirror(row)?
                                                       <MenuItem  value={'Mirror'}>
                                                            {'Mirror'}

                                                        </MenuItem> :<span/>
                                                        }
                                                    </TextField>
                                                        :
                                                        this.isMirror(row)?
                                                            <Button hidden={!this.isMirror(row)}
                                                                                          onClick={() => {
                                                            this.props.handleSelectAction(row.id, 'Mirror');
                                                        }}>Mirror</Button>:<span/>


                                                    }

                                                </TableCell>
                                                
                                                }
                                          
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>  
                            <TableFooter>
                                <TableRow>                      
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                                        //component="div"
                                        count={this.props.users.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}                                                                            
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                 </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>

            </div>
        );
    }
}
export default withStyles(useStyles)(ManageUsersTable);