import React, {Component, Fragment} from 'react'
import { Button, TextField, MenuItem, withStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { isNotEmpty,  isAlpha, isEmailAddress, validatePhone, hasLength, isName } from '../../utils/Validator.js';
import AuthenticationService from "../../Services/AuthenticationService";
import LoadingMsgBar from "../LoadingMsgBar";
import GlobalMessage from "../GlobalMessage";
import { isEmpty } from '../../utils/Utils.js';

const styles = () => ({
    root: {
        width: '100%',
    }
});

window.analytics ={pageName:'Manage Users: Edit Profile'};
class EditProfile extends Component {
    addErrorMessage(fieldName, errorMessage) {
        this.setState({
            [fieldName]: errorMessage
        });
    }

    clearErrorMessage(fieldName) {
        this.setState({
            [fieldName]: ''
        });
    }

    // save any changes the user makes into the state variable so you can
    // get back to the state the user was in
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checkecd : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    showLoadingIndicator(shouldShow) {
        this.setState({ loadingMessage: shouldShow ? `Please wait while we validate the data.` :'' });
    }


    constructor(props) {
        super(props);
        this.state = this.initializeState();
        this.usrfirstnameInput = React.createRef();
    }

    initializeState() {
        const initialState = {
            userDetailsInfo: null,
            loadingMessage: '',
            firstName:  '',
            lastName:  '',
            middleInitial:  '',
            phoneNumber:  '',
            emailAddress:  '',
            valid:true,
            isSaveEnabled: true,
            enlyteEmpId : '', isAdmin : false,
            affiliation:'Select One', affiliationList : [],
            affiliationDesc: '',
            jobTitle:'',
            companyName:'',
            companyURL: '',
            showAdditionalFields: false
        }

        return initialState;
    }

    loadAffiliationList() {
        const affilList = [];
            affilList.push({
                value: 'OFC_STAFF',
                label: 'Provider Office Staff'
            });
            affilList.push({
                value: 'BILLING_CO',
                label: 'Billing Company'
            });
            affilList.push({
                value: 'OTHER',
                label: 'Other'
            });
           
            this.setState({ affiliationList: affilList });        
        
    }

    clearErrorMessages() {
        const errorFieldList = [
            'firstNameError',
            'lastNameError',
            'phoneNumberError',
            'emailAddressError',
            'enlyteEmpIdError',
            'affiliationError',
            'jobTitleError',
            'companyNameError',
            'otherError'
        ];

        errorFieldList.forEach(errorFieldName => {
            this.clearErrorMessage(errorFieldName);
        });
    }

    getFormData() {
        return {  
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            middleInitial: this.state.middleInitial,
            phoneNumber: this.state.phoneNumber,
            emailAddress: this.state.emailAddress,
            
        }
    }


    doSyntaxValidation() {      
        let isValid = true;
        this.clearErrorMessages();
      

        if (!isName(this.state.firstName) || !hasLength(this.state.firstName, 1, 40)) {
            this.addErrorMessage("firstNameError", "Error: Enter valid First Name" );
            isValid = false;
        }

        if (!isName(this.state.lastName) || !hasLength(this.state.lastName, 1, 40)) {
            this.addErrorMessage("lastNameError","Error: Enter valid Last Name");
            isValid = false;
        }


        if (isNotEmpty(this.state.middleInitial) && !isAlpha(this.state.middleInitial)) {
            this.addErrorMessage("middleInitialError","Error: Enter Middle Initial" );
           
        }

        if (validatePhone(this.state.phoneNumber) === '') {
            this.addErrorMessage("phoneNumberError", "Error: Enter a valid Phone Number");
            isValid = false;
        }

        if (!isEmailAddress(this.state.emailAddress)) {
            this.addErrorMessage("emailAddressError","Error: Enter a valid Email Address");
            isValid = false;
        }

        if(this.state.showAdditionalFields){
            if(this.state.affiliation === 'Select One'){
                this.addErrorMessage("affiliationError","Error: Select Affiliation");
                isValid = false; 
                }
            if(this.state.affiliation === 'OTHER' && isEmpty(this.state.other)){
                    this.addErrorMessage("otherError","Error: Enter Other Desc");
                    isValid = false; 
                    }
            if(isEmpty(this.state.jobTitle)){
                this.addErrorMessage("jobTitleError","Error: Enter a valid Job Title");
                isValid = false;
            }
            if(isEmpty(this.state.companyName)){
                this.addErrorMessage("companyNameError","Error: Enter a valid company Name");
                isValid = false;
            }
        }

        return isValid;

    }

    updateUser()  {
        
        this.setState({progMsg: `Please wait while we update User.`, errorMsg:''});
        const plainPhoneNum =  this.state.phoneNumber.replace(/[^\d]/g, "");
        const newUserDetails = {"id":this.state.userDetailsInfo.id,"userNm":this.state.userDetailsInfo.userNm, 
        "userFirstName":this.state.firstName, "userLastName":this.state.lastName, 
        "userMidName":this.state.middleInitial,
        "emailAddr":this.state.emailAddress, 
        "phoneNum":plainPhoneNum, "employeeId":((this.state.enlyteEmpId === "") ? null :this.state.enlyteEmpId),
        "affiliationCd": ((this.state.affiliation === "Select One") ? '' : this.state.affiliation),
	    "affiliationDesc": this.state.other,
        "jobTitle": ((this.state.jobTitle === "") ? null :this.state.jobTitle),
        "companyNm": ((this.state.companyName === "") ? null :this.state.companyName),
        "companyUrl": ((this.state.companyURL === "") ? null :this.state.companyURL)
        };

        
        AuthenticationService.updateUsrDtls(newUserDetails)
        .then(response => {
            this.setState({isSaveEnabled: true});
            if(response.status === 200) {
                console.log(`update data success`)
            }          
            this.setState({loadingMessage: '', errorMsg:''});
            if(this.props.location.state.admin === true) {
                this.props.history.push({
                    pathname: '/wcpp/UserSearch',
                    state: {searchInfo: this.props.location.state.searchInfo}
                })
            } else {
                this.props.history.push({
                    pathname: '/wcpp/ManageUsers'
                })
            }
        }).catch((error) => {
            this.setState({isSaveEnabled: true});
            let emailMsg = '';
            if(null!= error.response){
                if(null!=error.response.data){
                    if(error.response.data.message === 'INVALID_ENLYTE_EMPLOYEE_ID'){
                        this.setState({ errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`}); 
                        this.addErrorMessage("enlyteEmpIdError","Error: Enter a valid Enlyte Employee ID");
                    } else{
                        emailMsg =  error.response.data.message;
                    }
                }
            }
            //emailMsg =  error.response.data.message;
            if(isNotEmpty(emailMsg)){
                emailMsg = 'Error: ' + emailMsg;
                this.setState({loadingMessage:'', errorMsg: emailMsg});
            }else{           
                this.setState({loadingMessage:'', errorMsg: `Error: An error has occurred. Please try again or contact Customer Service.`});
            }
        console.log(`update user ${error}`);
    })
  
       
    };
 
    
    handleOnBlurPhoneNum=(event) =>  {
        const changed = event.target.value;
        this.setState({phoneNumber : changed});
        if(changed.length < 10){
            this.setState({valid:false});
        }
        else{
            const phone = validatePhone(changed)
            if(phone.length < 10){
                this.setState({valid:false});
            }
            else
            {
                this.setState({phoneNumber : phone});
                this.addErrorMessage("phoneNumberError", "");
                this.setState({valid:true});
            }
        }
    }

    handleUpdate = () => {
       const isValid = this.doSyntaxValidation();
       this.setState({valid : isValid});
       if(isValid){
            this.setState({isSaveEnabled: false});
            this.setState({loadingMessage:`Please wait while we validate the data.`});
            this.updateUser();
        }else{
            this.setState({loadingMessage:'', errorMsg: `An error has occurred. Please address all fields marked with “Error”.`});
            this.setState({isSaveEnabled: true});
        }
    }
    
     handleCancel = () => {
        if(this.props.location.state.admin === true) {
            this.props.history.push({
                pathname: '/wcpp/UserSearch',
                state: {searchInfo: this.props.location.state.searchInfo}
            })
        } else {
            this.props.history.push({
                pathname: '/wcpp/ManageUsers'
            })
        }

     }
    
    componentDidMount() {
        
        const usr=this.props.location.state.user; 
        const admin = this.props.location.state.admin;
        this.loadAffiliationList();
        this.setState({ userDetailsInfo : usr});
        this.setState({ firstName: (usr.userFirstName !== null ? usr.userFirstName : '')});
        this.setState({lastName: (usr.userLastName !== null ? usr.userLastName : '')});
        this.setState({middleInitial: (usr.userMidName !== null ? usr.userMidName : '')});
        this.setState({emailAddress: (usr.emailAddr!== null ? usr.emailAddr : '')});
        this.setState({enlyteEmpId: (usr.employeeId !== null ? usr.employeeId : '')});
        this.setState({affiliation: (usr.affiliationCd !== null ? usr.affiliationCd : 'Select One')});
        this.setState({other: (usr.affiliationDesc !== null ? usr.affiliationDesc : '')});
        this.setState({jobTitle: (usr.jobTitle !== null ? usr.jobTitle : '')});
        this.setState({companyName: (usr.companyNm !== null ? usr.companyNm : '')});
        this.setState({companyURL: (usr.companyUrl !== null ? usr.companyUrl : '')});
       // this.setState({userTypeCd: (usr.typeCd !== null ? usr.typeCd : '')});
        if (usr.typeCd === "PRAD" || usr.typeCd === "PRUS") {
            this.setState({showAdditionalFields : true});
        }else{
            this.setState({showAdditionalFields : false});
        }
        this.setState({userNm: usr.userNm});
        let setIsAdmin = false;
        if((usr.enlyteEmployee === true) && admin){
            setIsAdmin = true;
        }
        this.setState({isAdmin: setIsAdmin});
        let phone = usr.phoneNum !== null ? usr.phoneNum : '';
        phone=validatePhone(phone)
        this.setState({phoneNumber: phone});
        this.setState({isSaveEnabled: true});
    };


    render() {
        return(
            <Fragment>

                 <div>
                <h1 className='App-page-label'>User Profile for {this.props.location.state.user.userFirstName} {this.props.location.state.user.userLastName}</h1>

                     <LoadingMsgBar loadMsg={this.state.loadingMessage}/>
                     <GlobalMessage  message={this.state.errorMsg} />

                <div className='App-note-Field App-error-font App-error-color'> Note: Fields marked with
                    an asterisk(*) are required.
                </div>
                <div className="step-main">
                    <Grid container direction="column">
                    {this.state.showAdditionalFields && (
                        <>
                    <Grid item style={{width:'50%', textAlign:'left'}}>
                    <TextField name="affiliation" id="affiliation" label="Affiliation"
                                error={isNotEmpty(this.state.affiliationError)} helperText={this.state.affiliationError}
                                defaultValue="Select One"
                                select
                                value={this.state.affiliation}
                                onChange={this.handleInputChange}
                                fullWidth
                                required
                                autoFocus
                            >
                                 <MenuItem key={'Select One'} value={'Select One'}>
                                        {'Select One'}
                                    </MenuItem>
                                {this.state.affiliationList.map(type => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            </Grid>
                            {(isNotEmpty(this.state.affiliation) && (this.state.affiliation === 'OTHER') ) ? 
                        
                        <Grid item style={{width:'50%', textAlign:'left'}}>
                             <TextField id="other" name="other" label="Other"
                                 error={isNotEmpty(this.state.otherError)} helperText={this.state.otherError}   
                                 value={this.state.other}
                                 onChange={this.handleInputChange}
                                 inputProps={{ maxLength: 40 }}
                                 fullWidth
                                 required
                             /> 
                            </Grid> : null}
                            </>)
                    }

                      
                     
                        <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField id="firstName" name="firstName" label="First Name"
                                error={isNotEmpty(this.state.firstNameError)} helperText={this.state.firstNameError} 
                                value={this.state.firstName}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 40 }}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField id="lastName" name="lastName" label="Last Name"
                                error={isNotEmpty(this.state.lastNameError)} helperText={this.state.lastNameError}
                                value={this.state.lastName}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 40 }}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField id="middleInitial" name="middleInitial" label="Middle Initial"
                                error={isNotEmpty(this.state.middleInitialError)} helperText={this.state.middleInitialError}
                                value={this.state.middleInitial}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 1 }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField id="phoneNumber" name="phoneNumber" label="Phone Number"
                                error={isNotEmpty(this.state.phoneNumberError)} helperText={this.state.phoneNumberError}
                                value={this.state.phoneNumber} 
                                onChange={this.handleInputChange} onBlur={this.handleOnBlurPhoneNum}
                                inputProps={{ maxLength: 15}}
                                fullWidth
                                required
                            />
                        </Grid>

                      

                        <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField id="emailAddress" name="emailAddress" label="Email Address"
                                error={isNotEmpty(this.state.emailAddressError)} helperText={this.state.emailAddressError}
                                value={this.state.emailAddress}
                                onChange={this.handleInputChange}
                                inputProps={{ maxLength: 120 }}
                                fullWidth
                                required
                            />
                        </Grid>

                        {this.state.showAdditionalFields && (
                        <>
                          <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="jobTitle" name="jobTitle" label="Job Title"
                            error={isNotEmpty(this.state.jobTitleError)} helperText={this.state.jobTitleError}
                             value={this.state.jobTitle}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid>

                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="companyName" name="companyName" label="Company Name"
                            error={isNotEmpty(this.state.companyNameError)} helperText={this.state.companyNameError}
                             value={this.state.companyName}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                             required
                         />
                     </Grid>
                     <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="companyURL" name="companyURL" label="Company URL"
                             value={this.state.companyURL}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                         />
                     </Grid>
                     </>)}

                        <Grid item style={{width:'50%', textAlign:'left'}}>
                            <TextField id="userName" name="userName" label="User Name"
                                autoFocus 
                                value={this.state.userNm}
                                fullWidth disabled={true}
                            />
                        </Grid>

                        { (this.props.location.state.admin === true)   ?
                        <Grid item style={{width:'50%', textAlign:'left'}}>
                         <TextField id="enlyteEmpId" name="enlyteEmpId" label="Enlyte Employee ID"
                            error={isNotEmpty(this.state.enlyteEmpIdError)} helperText={this.state.enlyteEmpIdError}
                             value={this.state.enlyteEmpId}
                             onChange={this.handleInputChange}
                             inputProps={{ maxLength: 120 }}
                             fullWidth
                         />
                     </Grid> : null }
                  
                    

                   
                     
                    </Grid>
                </div>

                <nav>              
                    <Button disabled={!this.state.isSaveEnabled || isNotEmpty(AuthenticationService.getMirrorUser())} onClick={this.handleUpdate}>SAVE</Button>
                    <Button variant="outlined" onClick={this.handleCancel}>CANCEL</Button>
                </nav>
            </div>
               
            </Fragment>

        );

    }
}

export default withStyles(styles)(EditProfile);