import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";

import AuthenticationService from "../Services/AuthenticationService";


function desc(a, b, orderBy) {
    const c = a.properties[orderBy] !== null? a.properties[orderBy].toUpperCase() : a.properties[orderBy];
    const d = b.properties[orderBy] !== null? b.properties[orderBy].toUpperCase() :b.properties[orderBy];
    if (d < c) {
        return -1;
    }
    if (d > c) {
        return 1;
    }
    return 0;
}

function
stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    {id: 'name', fid: 'properties.name', numeric: false, disablePadding: true, label: 'Document Title', filterLable:' Name',width:'7rem'}   
];

function EnhancedTableHead(props) {
    const {classes, order, orderBy, onRequestSort, onRequestFilter} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const onFilter = property => event => {
        onRequestFilter(event.target.value, property);

    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{maxWidth:headCell.width}}
                        autoFocus={headCell.id === 'name'}
                    >
                        <Grid container direction="column" style={{verticalAlign:'top'}}>
                            <Grid item>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <div className='Provider-resources-tbl-hdr'>
                                <div>
                                {headCell.label}

                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>) : null}</div>

                            </div>
                        </TableSortLabel>
                            </Grid>
                           
                        </Grid>
                    </TableCell>
                ))}
               
            </TableRow>
            <TableRow className='filter-row' style={{borderTop:'none'}}>
                {headCells.map(headCell => (

                    <TableCell

                        key={headCell.id+1}
                        padding={headCell.disablePadding ? 'none' : 'default'}

                        style={{ maxWidth: headCell.width, paddingBottom:0,borderTop:'none', paddingTop: 0}}
                        autoFocus={headCell.id === 'name'}
                    >
                        <div  style={{paddingRight:'.5rem',verticalAlign:'bottom'}}>
                           
                                <TextField
                                    id="filterId"
                                    style={{ width: '50rem'}}
                                    autoFocus={headCell.id === 'name'}
                                    placeholder={`Enter ${headCell.filterLable}`}
                                    margin="dense"
                                    variant="outlined"
                                    onChange={onFilter(headCell.fid)}/>
                            
                        </div>

                    </TableCell>

                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onRequestFilter: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {

    return ({}
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),     
    },   
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}               
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
      );
}


const useStyles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 450,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

});

function getResourceFileName(resourceLocator) {
    let date = new Date();
	resourceLocator = resourceLocator.replace(/\/$/, '');
	let parts = resourceLocator.split('/');
    let fileName = parts[parts.length - 1];
	fileName = decodeURIComponent(fileName);
	fileName = fileName.replace(/\.[^/.]+$/, '');
	
    return `${fileName}_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}-${date.getMilliseconds()}.pdf`;
}

class ProviderResourcesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc' ,orderBy:'name', page: 0, rowsPerPage: 10
        }

    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({order: isDesc ? 'asc' : 'desc', orderBy: property});
    }

    handleRequestFilter = (value, property) => {
        console.log(this.props.resources);
        console.log(`filter value->${value} and the property->${property}`)
        if( value.length < 1){
           
            this.props.setFilter(this.props.resources);
            return true;
        }else{
            this.props.setFilter(this.props.resources.filter(res => {
                let cValue = value.toUpperCase();
                let pValue = res.properties.name
                if(pValue && pValue.toUpperCase().search(cValue) !== -1) {
                    this.setState({ page: 0 });
                    return res;
                }
                return false;
                }))
           
        }

    }

    
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage})
    }

    handleChangeRowsPerPage = event => {
        this.setState({page: 0, rowsPerPage: event.target.value})
    }

    handleResource = (resourceLocator) => {
        AuthenticationService.getResource(resourceLocator)
        .then((response) => {
            console.log("Response Content-Type:", response.headers['content-type']);
            
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            
            // Create a hidden iframe
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            
            // Write the PDF to the iframe
            iframe.contentWindow.document.open('application/pdf');
            iframe.contentWindow.document.write(`
                <html>
                    <head>
                        <title>${getResourceFileName(resourceLocator)}</title>
                    </head>
                    <body style="margin:0;padding:0;">
                        <embed width="100%" height="100%" src="${url}" type="application/pdf">
                    </body>
                </html>
            `);
            iframe.contentWindow.document.close();
    
            // Open the iframe content in a new tab
            const newWindow = window.open('', '_blank');
            if (newWindow) {
                newWindow.document.write(iframe.contentWindow.document.documentElement.outerHTML);
                newWindow.document.close();
            } else {
                console.error("Failed to open new window. Popup might be blocked.");
                //this.updateGlobalMessage("Error: Unable to open the PDF. Please check your popup blocker settings.");
            }
    
            // Clean up
            document.body.removeChild(iframe);
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 1000);
        })
        .catch(error => {
            console.error("File download Link Error:", error);
            //this.updateGlobalMessage("Error: There was an error retrieving data. Please try again or contact Customer Service.");
            if (error.response) {
                console.error("Error response:", error.response);
            }
        });
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                        component="div"
                        count={this.props.filteredUsers.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        ActionsComponent={TablePaginationActions}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                    {/*  <EnhancedTableToolbar numSelected={selected.length} />*/}
                    <div className={classes.tableWrapper}>
                        <Table
                            className={`${classes.table} App-font`}
                            aria-labelledby="tableTitle"
                            size='small'
                            striped="true"
                            aria-label="Provider Resources table"
                        >
                            <EnhancedTableHead
                                classes={classes}

                                order={this.state.order}
                                orderBy={this.state.orderBy}                              
                                onRequestSort={this.handleRequestSort} 
                                onRequestFilter={this.handleRequestFilter}                              
                                rowCount={this.props.filteredUsers.length}

                            />
                            <TableBody>
                                {stableSort(this.props.filteredUsers, getSorting(this.state.order, this.state.orderBy))
                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((row, index) => {

                                        const labelId = `enhanced-table-Select-${index}`;
                                        const docLink = row.links[0].href.replace('.json','').replace('api/assets','content/dam').replace('/aem','') ;
                                      
                                        return (
                                            <TableRow
                                                tabIndex={-1}
                                                key={index}
                                                style={{
                                                    padding: '5px 20px',
                                                    height: 25,
                                                    backgroundColor: index % 2 ? '#fafafa' : 'white'
                                                }}
                                            >

                                                {/* <TableCell component="th" id={labelId} scope="row" padding="none" size='small'>
                                                     <a href={docLink} rel="noopener noreferrer"  target="_blank">{row.properties.name}</a>
                                                </TableCell>  */}
                                                <TableCell component="th" id={labelId} scope="row" padding="none" size='small'>
                                                    <Link color='primary'
                                                        onClick={(event) => {
                                                            this.handleResource(docLink);
                                                        }}
                                                        aria-label='' >
                                                        {row.properties.name}
                                                    </Link>	
                                                </TableCell> 
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}                                    
                                    count={this.props.filteredUsers.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    ActionsComponent={TablePaginationActions}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>                 
                </Paper>

            </div>
        );
    }
}
export default withStyles(useStyles)(ProviderResourcesTable);